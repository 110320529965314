/**
 * Just view/template component, no model/data logic!
 */
import React from "react"
import Layout from "./../../layout"
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "./../../seo"

// s4n
import { useIntl } from "gatsby-plugin-intl"
import Link from './../Link'
// import "./../../assets/sass/templates/node/_blog.scss"
import classNames from 'classnames';
import kebabCase from "lodash.kebabcase"
// import LogoHeuresis from './../../../images/custom/heuresis-logo.svg'

import CollectionDataUtils from "./../../../utils/Collection/data"
import Tags from "./tags"
import PromotedFrontPage from './../Promoted/FrontPage'


const ComponentStateless = (props) => {
    const intl = useIntl();
	const t = intl.formatMessage;
	
  	const { data } = props;

// console.log(data)

	const CoverClass = classNames({
		'wp-block-cover _has-background-dim': true,
		's4n-has-no-background': !data.img,
	});

	let styleCover = {
		'background-color': `rgba(0, 51, 153, 1)`,
		'background-position': `center`, // `49.4565% 0%`,
		'background-repeat': `no-repeat`,
	}

	let meta = [];

	if (data.img) {
		styleCover['background-image'] = `url('${data.img.src}')`;

		meta.push(
		{
			property: `image`,
			content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
		}, 
		{
			property: `og:image`,
			content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
		},
		{
			property: `twitter:image`,
			content: `${process.env.SOFT4NET_STATIC_BASE_URL}${data.img.src}`,
		}
		);
	}

	const linkMap = {
		pl: {
			blog: `Blog`,
			news: `Aktualności`,
		},
		en: {
			blog: `Blog`,
			news: `News`,
		},
		de: {
			blog: `Blog`,
			news: `News`,
		},
	}
	
	const getAllBlogTags = () => {
		let tags = [];;

		// @see: https://www.gatsbyjs.org/docs/use-static-query/
		const allNodeBlog = useStaticQuery(graphql`
			query {
				blog: allNodeBlog {
					edges {
						node {
							drupal_internal__nid # important for CollectionDataUtils.filterData(items, intl.locale);
							langcode
							path {
								langcode
							}
							title
							field_type
							relationships {
								bundle: __typename
								field_tags {
									... on taxonomy_term__tags {
										name
									}
								}
							}
						}
					}
				}
			}
		`)

		const blogItems = allNodeBlog.blog.edges.filter(({ node }) => {
			return (
				'SOFT4NET_DO_NOT_DELETE' !== node.title // no special node for filling missing fields in GraphQL
				&& 'blog' === node.field_type
			)
		});

		const aDataCollectionTranslated = CollectionDataUtils.filterData(blogItems, intl.locale);

        // Creating blog/news posts
        aDataCollectionTranslated.forEach(({ node }, index, arr) => {
			node.relationships.field_tags && node.relationships.field_tags.forEach(tag => {
            	tags.push(tag.name)
	        });
		})
		
        const countTags = tags.reduce((prev, curr) => {
          prev[curr] = (prev[curr] || 0) + 1;
          return prev;
		}, {});

        const allTags = Object.keys(countTags);

		return allTags;
	}
	
	const pageContext = {
		allTags: getAllBlogTags()
	}
	const linkTranslation = kebabCase(linkMap[intl.locale][data.node.field_type]);

  return (
    <Layout node={data.node}>
        <SEO title={`${data.title} | Blog`} keywords={`blog`} meta={meta} nodeId={data.node.drupal_internal__nid} />

        {/* Old header */}
        {/* <div 
          	className={CoverClass}
          	// data-entity-type="file" data-entity-uuid="27469459-3f6a-497d-8a82-fd55a1065a67" data-image-style="original" 
          	style={styleCover}
        >
        	<div className="wp-block-cover__inner-container">
            	<h1 className="text-center pb-1 pb-sm-3 pb-lg-5">{data.title}</h1>
            	<span className="posted_by d-block text-center text-white">{data.created}</span>
            	<h2 className="has-text-align-center">Zapraszamy do współpracy</h2>
            	<p className="text-center">Chcemy dzielić się naszym doświadczeniem, wiedzą,oraz wypracowanymi i sprawdzonymi rozwiązaniami i narzędziami. Szukamy osób indywidualnych i instytucji szkoleniowych do współpracy. Niezależnie od formy współpracy, stawiamy na jakość, skuteczność, troskę oraz uczciwość. </p>
          	</div>
        </div> */}

		<section className="section">
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className={`items`}>
							<Tags pageContext={pageContext} linkTranslation={linkTranslation} columnClass={`col-12`} />
						</div>
					</div>
					<div className="col-lg-7 ml-lg-auto">
						<div className="post post-single">
							{data.img && 
								<div className="post-thumb">
									<div className={`circle half bottom bg-white`}>{data.created}</div>
									<Img className="img-fluid w-100" fluid={data.img} />
								</div>
							}
							<h2 className="post-title">{data.title}</h2>
							<div className="post-meta">
								<ul>
									{/* <li>
										<i className="ti-calendar"></i>{` `}{data.created}
									</li> */}
									{/* <li>
										<i className="ti-user"></i>{` `}POSTED BY ADMIN
									</li> */}
									{data.node.relationships.field_tags && !!data.node.relationships.field_tags.length && 
										<li>
											<i className="ti-tag"></i>{` `}
											{/* { t({ id: "soft4net_lists_division_tags" }) }: */}
											{data.node.relationships.field_tags.map((tag, i) => {
												const isLastItem = data.node.relationships.field_tags.length === i + 1;

												return (
													<React.Fragment>
														<Link to={`/${linkTranslation}/tag/${kebabCase(tag.name)}`} title={tag.name} className={``}>{tag.name}</Link>
														{ isLastItem ? `` : `, ` }
													</React.Fragment>
												)
											})}
										</li>
									}
									{/* <li>
										<a href=""><i className="ti-comments"></i>{` `}4 COMMENTS</a>
									</li> */}
								</ul>
							</div>
							<div className="post-content post-excerpt mb-5">
								{data.bodyValueEnchanced}
							</div>
							
							{false &&
								<div className="post-social-share mb-4">
									<h4 className="post-sub-heading border-bottom pb-3 mb-3">Share this post</h4>
									<div className="social-media-icons">
										<ul className="list-inline social-icons">
											<li className="list-inline-item"><a href="#"><i className="ti-facebook"></i></a></li>
											<li className="list-inline-item"><a href="#"><i className="ti-twitter-alt"></i></a></li>
											<li className="list-inline-item"><a href="#"><i className="ti-vimeo-alt"></i></a></li>
											<li className="list-inline-item"><a href="#"><i className="ti-google"></i></a></li>
										</ul>
									</div>
								</div>
							}

							{false && <></>
								// <div className="post-comments">
								// 	<h4 className="post-sub-heading border-bottom pb-3 mb-3">10 Comments</h4>
								// 	<ul className="media-list mb-5">
								// 	{/* <!-- Comment Item start--> */}
								// 	<li className="media">
								// 		<a href="#">
								// 		<img className="img-fluid" src="images/blog/member-1.jpg" alt="" width="50" height="50" />
								// 		</a>
								// 		<div className="media-body">
								// 		<div className="comment-info">
								// 			<div className="comment-author">
								// 			<a href="#">Jonathon Andrew</a>
								// 			</div>
								// 			<time>July 02, 2015, at 11:34</time>
								// 			<a className="comment-button" href="#"><i className="ti-comments"></i>Reply</a>
								// 		</div>
								// 		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at magna ut ante eleifend
								// 			eleifend.</p>
								// 		{/* <!--  second level Comment start--> */}
								// 		<div className="media">
								// 			<a href="#">
								// 			<img className="img-fluid" src="images/blog/member-2.jpg" alt="" width="50" height="50" />
								// 			</a>
								// 			<div className="media-body">
								// 			<div className="comment-info">
								// 				<div className="comment-author">
								// 				<a href="#">Senorita</a>
								// 				</div>
								// 				<time>July 02, 2015, at 11:34</time>
								// 				<a className="comment-button" href="#"><i className="ti-comments"></i>Reply</a>
								// 			</div>
								// 			<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at magna ut ante eleifend
								// 				eleifend.</p>
								// 			{/* <!-- third level Comment start --> */}
								// 			<div className="media">
								// 				<a href="#">
								// 				<img className="img-fluid" src="images/blog/member-1.jpg" alt="" width="50"
								// 					height="50" />
								// 				</a>
								// 				<div className="media-body">
								// 				<div className="comment-info">
								// 					<div className="comment-author">
								// 					<a href="#">Senorita</a>
								// 					</div>
								// 					<time>July 02, 2015, at 11:34</time>
								// 					<a className="comment-button" href="#"><i className="ti-comments"></i>Reply</a>
								// 				</div>
								// 				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at magna ut ante eleifend
								// 					eleifend.</p>
								// 				</div>
								// 			</div>
								// 			{/* <!-- third level Comment end --> */}
								// 			</div>
								// 		</div>
								// 		{/* <!-- second level Comment end --> */}
								// 		</div>
								// 	</li>
								// 	{/* <!-- End Comment Item --> */}

								// 	{/* <!-- Comment Item start--> */}
								// 	<li className="media">
								// 		<a href="#">
								// 		<img className="img-fluid" src="images/blog/member-3.jpg" alt="" width="50" height="50" />
								// 		</a>
								// 		<div className="media-body">
								// 		<div className="comment-info">
								// 			<div className="comment-author">
								// 			<a href="#">Jonathon Andrew</a>
								// 			</div>
								// 			<time>July 02, 2015, at 11:34</time>
								// 			<a className="comment-button" href="#"><i className="ti-comments"></i>Reply</a>
								// 		</div>
								// 		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at magna ut ante eleifend eleifend.</p>
								// 		</div>
								// 	</li>
								// 	{/* <!-- End Comment Item --> */}

								// 	{/* <!-- Comment Item start--> */}
								// 	<li className="media">
								// 		<a href="#">
								// 			<img className="img-fluid" src="images/blog/member-2.jpg" alt="" width="50" height="50" />
								// 		</a>
								// 		<div className="media-body">
								// 			<div className="comment-info">
								// 				<div className="comment-author">
								// 				<a href="#">Jonathon Andrew</a>
								// 				</div>
								// 				<time>July 02, 2015, at 11:34</time>
								// 				<a className="comment-button" href="#"><i className="ti-comments"></i>Reply</a>
								// 			</div>
								// 			<p>
								// 				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque at magna ut ante eleifend eleifend.
								// 			</p>
								// 		</div>
								// 	</li>
								// 	{/* <!-- End Comment Item --> */}
								// 	</ul>
								// </div>

								// <div className="post-comments-form">
								// 	<h4 className="post-sub-heading mb-3">Leave You Comments</h4>
								// 	<form method="post" action="#" id="form" role="form">
								// 		<div className="row">
								// 			<div className="col-md-6 form-group">
								// 				{/* <!-- Name --> */}
								// 				<input type="text" name="name" id="name" className=" form-control" placeholder="Name *" maxlength="100" required="" />
								// 			</div>
								// 			<div className="col-md-6 form-group">
								// 				{/* <!-- Email --> */}
								// 				<input type="email" name="email" id="email" className=" form-control" placeholder="Email *" maxlength="100" required="" />
								// 			</div>
								// 			<div className="form-group col-md-12">
								// 				{/* <!-- Website --> */}
								// 				<input type="text" name="website" id="website" className=" form-control" placeholder="Website" maxlength="100" />
								// 			</div>
								// 			{/* <!-- Comment --> */}
								// 			<div className="form-group col-md-12">
								// 				<textarea name="text" id="text" className=" form-control" rows="6" placeholder="Comment" maxlength="400"></textarea>
								// 			</div>
								// 			{/* <!-- Send Button --> */}
								// 			<div className="form-group col-md-12">
								// 				<button type="submit" className="btn btn-primary">Send comment</button>
								// 			</div>
								// 		</div>
								// 	</form>
								// </div>
							}

						</div>
					</div>
				</div>
				<div className="row">
					<div className="col text-center pt-5">
						<Link to={`/blog`} title={`Blog`} className="btn btn-primary">Blog</Link>
					</div>
				</div>
			</div>
		</section>

    </Layout>
  )
}

export default ComponentStateless